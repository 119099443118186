import { Link } from "react-router-dom";
import MagicFormulaArticle from "../article/MagicFormulaArticle";
import BerskshireArticle from "../article/BerskshireArticle";
import BillAckmanArticle from "../article/BillAckmanArticle";
import MichaelBurryArticle from "../article/MichaelBurryArticle";
import BuffetologyHagstromArticle from "../article/BuffetologyHagstromArticle";
import OShaugnessyTinyTitansArticle from "../article/OShaugnessyTinyTitansArticle";
import KirkPatrickValueScreen from "../article/KirkPatrickValueScreen";
import ONeilsCANSLIMArticle from "../article/ONeilsCANSLIMArticle";
import GrahamDefensive from "../article/GrahamDefensive";
import GrahamEnterprising from "../article/GrahamEnterprising";
import StandardSell from "../whenToSell/StandardSell";
import MagicFormulaSell from "../whenToSell/MagicFormulaSell";
import KirkpatrickSell from "../whenToSell/KirkpatrickSell";

// Idea :  CANSLIM, Neff and Zweig Zweig 26

const dataScreener_People = {
  warrenbuffet: {
    isFree: false,
    dataEndPoint: {
      url: "warrenbuffet",
      perf: "",
      screener: "warrenbuffet",
    },
    name: "Berkshire Hathaway Inc.",
    author: "Warren Buffet",
    description:
      "Berkshire Hathaway, run by legendary billionaire investor Warren Buffett, is a holding company engaged in a number of diverse business activities. Buffett is regarded as the ultimate value investor following the Benjamin Graham approach to finding value. Nevertheless, the portfolio of Berkshire Hathaway has constraints due to its size and may not truly represent th Waren Buffet investing style.",
    tags: [],
    typeOfScreener: "people",
    filters: ["people", "value"],
    plotValue: [161, 213, 204, 224, 235, 299, 318, 415],
    performances: { ytd: 2, "5y": 3, "10y": 157, "15y": 14, risk: "N/A", holding: 50 },
    article: <BerskshireArticle />,
    whentosell: <StandardSell />,
    SEODescription:
      "Discover the holding of Warren Buffett, an American Billionnaire founder of Berkshire Hathaway and inspired by the legendary Benjamin Graham.",
    aboutTheBook: " Warren Buffett, an American Billionnaire founder of Berkshire Hathaway and inspired by the legendary Benjamin Graham.",
  },
  michaelburry: {
    isFree: false,
    dataEndPoint: {
      url: "michaelburry",
      perf: "",
      screener: "michaelburry",
    },
    name: "Scion Asset Management",
    author: "Michael J. Burry",
    description:
      "Scion Asset Management is based out of Saratoga and is run by Dr. Michael J. Burry. Scion Asset Management is a hedge fund with 4 clients and discretionary assets under management.",
    tags: [],
    typeOfScreener: "people",
    filters: ["people"],
    plotValue: [14, 23, 14, 23, 66, 178, 214, 210],
    performances: { ytd: 2, "5y": 3, "10y": 254, "15y": 11, risk: "N/A", holding: 50 },
    article: <MichaelBurryArticle />,
    whentosell: <StandardSell />,
    SEODescription:
      "Discover the holding of Michael James Burry, an American Investor founder of Scion Asset Management. He became popular in The Big Short movie.",
    aboutTheBook:
      "Michael James Burry is an American investor and hedge fund manager. He founded the hedge fund Scion Capital, which he ran from 2000 to 2008 before closing it to focus on his personal investments. He is best known for being one of the first investors to predict and profit from the subprime mortgage crisis that occurred between 2007 and 2010.",
  },
  billackman: {
    isFree: false,
    dataEndPoint: {
      url: "billackman",
      perf: "",
      screener: "billackman",
    },
    name: "Pershing Square Capital",
    author: "Bill Ackman",
    description: "Pershing Square Capital is a New York based hedge fund managed by Bill Ackman.",
    tags: [],
    typeOfScreener: "people",
    filters: ["people"],
    plotValue: [33, 35, 104, 106, 97, 115, 150, 165],
    performances: { ytd: 2, "5y": 3, "10y": 144, "15y": 8, risk: "N/A", holding: 50 },
    article: <BillAckmanArticle />,
    whentosell: <StandardSell />,
    SEODescription: "Discover the holding of William Albert Ackman, an American Billionnaire founder of Pershing Square Capital.",
    aboutTheBook:
      "William Albert Ackman is an American billionaire hedge fund manager who is the founder and chief executive officer of Pershing Square Capital Management",
  },
};

const dataScreener_Book = {
  magicformula: {
    isFree: true,
    dataEndPoint: {
      url: "magicformula",
      perf: "magicformula",
      screener: "magicformula",
    },
    name: "Magic Formula",
    author: "Joel Greenblat",
    description: (
      <>
        <p>
          In short, the strategy focuses on large capitalization within the United States. The author doesn't look at sensible sectors such as
          financials and utilities. We look for companies with the higher{" "}
          <Link className="link-content" to={`${process.env.PUBLIC_URL}/docs/return-on-capital`}>
            ROC (Return on Capital)
          </Link>{" "}
          and{" "}
          <Link className="link-content" to={`${process.env.PUBLIC_URL}/docs/earnings-yield`}>
            Earnings Yield
          </Link>
        </p>
        <p className="font-bold mt-3">In a nutshell:</p>
        <ul className="space-y-1 list-disc list-inside">
          <li>Market Cap.{">"} $100M. </li>
          <li>Exclude financial or utility stocks</li>
        </ul>
      </>
    ),
    // tags: [],
    typeOfScreener: "book",
    filters: ["book", "value", "quality"],

    performances: { ytd: 2.3, "5y": -2.6, "10y": -0.5, "15y": 5.9, risk: 58, holding: 80 },
    plotValue: [33, 62, 97, 88, 69, 83, 106, 79],
    article: <MagicFormulaArticle />,
    whentosell: <MagicFormulaSell />,
    SEODescription: "Free Access to the Greenblatt's Magic Formula stocks screener from The Little Book That Beats the Market.",
    aboutTheBook:
      "In 2005, Joel Greenblatt published a book that is already considered one of the classics of finance literature. In The Little Book that Beats the Market--a New York Times bestseller with 300,000 copies in print--Greenblatt explained how investors can outperform the popular market averages by simply and systematically applying a formula that seeks out good businesses when they are available at bargain prices.",
  },

  graham_defensive: {
    isFree: false,
    dataEndPoint: {
      url: "graham_defensive",
      perf: "graham_defensive",
      screener: "graham_defensive",
    },
    name: "The Intelligent Investor : Defensive Stocks",
    author: "Benjamin Graham",
    description: <p>A strategy based on multiple strict criteria such consistent earning growth to find stable business over years.</p>,
    tags: [],
    typeOfScreener: "book",
    filters: ["book", "value", "yield", "quality"],

    performances: { ytd: 1.3, "5y": 7.2, "10y": 4.2, "15y": 12.1, risk: 35, holding: 80 },
    plotValue: [443, 581, 474, 599, 617, 746, 814, 873],
    article: <GrahamDefensive />,
    whentosell: <StandardSell />,
    SEODescription: "Graham's philosophy of Value investing sums up in a screener on the US Market. Be the Intelligent Investor",
    aboutTheBook: (
      <p>
        The greatest investment advisor of the twentieth century, Benjamin Graham, taught and inspired people worldwide. Graham's philosophy of "value
        investing" -- which shields investors from substantial error and teaches them to develop long-term strategies -- has made The Intelligent
        Investor the stock market bible ever since its original publication in 1949.
      </p>
    ),
  },
  graham_enterprising: {
    isFree: false,
    dataEndPoint: {
      url: "graham_enterprising",
      perf: "graham_enterprising",
      screener: "graham_enterprising",
    },

    name: "The Intelligent Investor : Enterprising Stocks",
    author: "Benjamin Graham",
    description: <p>A strategy less strict than the Defensive Approach to find stable business over years with a larger scope.</p>,
    tags: [],
    typeOfScreener: "book",
    filters: ["book", "value", "yield", "quality"],
    performances: { ytd: 31.7, "5y": 28.2, "10y": 17.5, "15y": 20.7, risk: 73, holding: 70 },
    plotValue: [87, 66, 40, 60, 96, 215, 248, 426],
    article: <GrahamEnterprising />,
    whentosell: <StandardSell />,
    SEODescription: "Graham's philosophy of Value investing sums up in a screener on the US Market. Be the Intelligent Investor",

    aboutTheBook: (
      <p>
        The greatest investment advisor of the twentieth century, Benjamin Graham, taught and inspired people worldwide. Graham's philosophy of "value
        investing" -- which shields investors from substantial error and teaches them to develop long-term strategies -- has made The Intelligent
        Investor the stock market bible ever since its original publication in 1949.
      </p>
    ),
  },

  o_neils_can_slim: {
    isFree: false,
    dataEndPoint: {
      url: "o_neils_can_slim",
      perf: "o_neils_can_slim",
      screener: "o_neils_can_slim",
    },
    name: "CANSLIM",
    author: "William J. O'Neil",
    description: (
      <>
        <p>
          Find equities that are expected to expand faster than average. Each letter in the acronym represents a vital criterion to consider when
          acquiring stocks.
        </p>
      </>
    ),
    // tags: [],
    typeOfScreener: "book",
    filters: ["book", "momentum", "growth"],
    performances: { ytd: 11.7, "5y": 15.3, "10y": 10.3, "15y": 12.4, risk: 89, holding: 60 },
    plotValue: [40, 95, 32, 25, 88, 173, 142, 187],
    article: <ONeilsCANSLIMArticle />,
    whentosell: <StandardSell />,
    SEODescription: "Discover the screener inspired by O'Neils in How to Make Money in Stocks, known as the CANSLIM. ",
    aboutTheBook: `In 2009, in the wake of the financial crisis, William O'Neal published "How to Make Money in Stocks", a revolutionary guide that has sold 2 million copies to date`,
  },

  buffettology_hagstrom: {
    isFree: false,
    dataEndPoint: {
      url: "buffettology_hagstrom",
      perf: "buffettology_hagstrom",
      screener: "buffettology_hagstrom",
    },
    name: "The Warren Buffett Way",
    author: "Robert Hagstrom",
    description: (
      <>
        Warren Buffett is the most famous investor of all time and one of today’s most admired business leaders. He became a billionaire and
        investment sage by looking at companies as businesses rather than prices on a stock screen. The Warren Buffett Way is a one-of-a-kind
        investment guide that explains the winning strategies of the master.
      </>
    ),
    // tags: [],
    typeOfScreener: "book",
    filters: ["book", "value", "quality"],
    performances: { ytd: 17.2, "5y": 14.5, "10y": 10.8, "15y": 14.7, risk: 11, holding: 60 },
    plotValue: [33, 58, 32, 66, 120, 161, 135, 204],
    article: <BuffetologyHagstromArticle />,
    whentosell: <StandardSell />,
    SEODescription:
      "Discover the screener inspired by Warren Buffet described by Robert Hagstrom in his books The Warren Buffett Way & The Essential Buffett",
    aboutTheBook:
      "Warren Buffett is the most famous investor of all time and one of today’s most admired business leaders. He became a billionaire and investment sage by looking at companies as businesses rather than prices on a stock screen. The Warren Buffett Way is a one-of-a-kind investment guide that explains the winning strategies of the master.",
  },

  o_shaughnessy_tiny_titans: {
    isFree: false,
    dataEndPoint: {
      url: "o_shaughnessy_tiny_titans",
      perf: "o_shaughnessy_tiny_titans",
      screener: "o_shaughnessy_tiny_titans",
    },
    name: "Tiny Titans",
    author: "James O'Shaughnessy",
    description: (
      <>
        By looking at small-cap companies, O'Shaughnessy expects to find under-the-radar companies with huge potential. These companies are also less
        correlated to the market.
      </>
    ),
    // tags: [],
    typeOfScreener: "book",
    filters: ["book", "momentum"],
    performances: { ytd: 8.5, "5y": 30.1, "10y": 19.4, "15y": 22.0, risk: 90, holding: 40 },
    plotValue: [36, 27, 68, 87, 171, 340, 407, 544],
    article: <OShaugnessyTinyTitansArticle />,
    whentosell: <StandardSell />,
    SEODescription: "Discover the Screener for The Tiny Titans developped by O'Shaughnessy to find high potential stocks.",
    aaii: "https://www.aaii.com/stocks/screens/59",
    aboutTheBook: `Predicting the future of the market is nearly impossible, but that doesn't stop investors from trying. In his latest book, "Predicting the Markets of Tomorrow: A Contrarian Investment Strategy for the Next Twenty Years," James O'Shaughnessy argues that investors can predict where the markets are headed simply by looking at historical long-term trends.`,
  },

  kirkpatrick_value_screen: {
    isFree: false,
    dataEndPoint: {
      url: "kirkpatrick_value_screen",
      perf: "kirkpatrick_value_screen",
      screener: "kirkpatrick_value_screen",
    },

    name: "Value Screen",
    author: "Kirkpatrick",
    description: <>The screen focus on relative low-valued stocks with high performing return in the last 6 months.</>,
    // tags: [],
    typeOfScreener: "book",
    filters: ["book", "value", "momentum"],
    performances: { ytd: 16.0, "5y": 26.6, "10y": 8.1, "15y": 3.9, risk: 109, holding: 60 },
    plotValue: [495, 750, 380, 381, 844, 1432, 1384, 1717],
    article: <KirkPatrickValueScreen />,
    whentosell: <KirkpatrickSell />,
    SEODescription: "Discover the Value Screen developped by Charles Kirkpatrick in Beat The Market",
    aaii: "https://www.aaii.com/stocks/screens/43",
    aboutTheBook: `Kirkpatrick developped his strategy in his "Beat the Market: Invest by Knowing What Stocks to Buy and What Stocks to Sell"`,
  },
};

let dataScreener_Book_etoro = {};
let dataScreener_Book_robinhood = {};

Object.keys(dataScreener_Book).forEach((book) => {
  const newName = book + "_etoro";
  dataScreener_Book_etoro[newName] = {
    ...dataScreener_Book[book],
    name: dataScreener_Book[book]["name"] + " on eToro",
    isFree: false,
    dataEndPoint: {
      url: dataScreener_Book[book]["dataEndPoint"]["url"] + "_etoro",
      perf: dataScreener_Book[book]["dataEndPoint"]["perf"],
      screener: dataScreener_Book[book]["dataEndPoint"]["screener"] + "_etoro",
    },

    filters: [...dataScreener_Book[book]["filters"], "eToro"],
  };
});

Object.keys(dataScreener_Book).forEach((book) => {
  const newName = book + "_robinhood";
  dataScreener_Book_robinhood[newName] = {
    ...dataScreener_Book[book],
    name: dataScreener_Book[book]["name"] + " on Robinhood",
    isFree: false,
    dataEndPoint: {
      url: dataScreener_Book[book]["dataEndPoint"]["url"] + "_robinhood",
      perf: dataScreener_Book[book]["dataEndPoint"]["perf"],
      screener: dataScreener_Book[book]["dataEndPoint"]["screener"] + "_robinhood",
    },

    filters: [...dataScreener_Book[book]["filters"], "Robinhood"],
  };
});

export const dataScreener = {
  ...dataScreener_Book,
  ...dataScreener_Book_etoro,
  ...dataScreener_Book_robinhood,
  ...dataScreener_People,
};
